import {Calendar} from 'fullcalendar';

let selectedDay;

function colorSelectedDay(tourId) {
  const dayElements = [...document.querySelectorAll(`#calendar-${tourId} .fc-day`)];

  dayElements.forEach(day => day.style.backgroundColor = '');

  const dayElement = document.querySelector(`#calendar-${tourId} td[data-date="${selectedDay}"]`);

  if (dayElement) {
    dayElement.style.backgroundColor = 'color-mix(in srgb, var(--color-highlight-light), transparent 66%)';
  }
}

/**
 * This handler handles the click on the date, so all the events of the date get printed into the extension
 * @param {string} dateStr
 * @param {string} tourId
 */
function onClickDate(dateStr, tourId = '') {
  if (!dateStr) {
    throw new Error('no date string provided.')
  }

  selectedDay = dateStr;

  const clickedDate = new Date(selectedDay);
  clickedDate.setHours(0,0,0)

  colorSelectedDay(tourId);

  let eventListSelector = `#calendar-event-list-${tourId}`;

  const eventList = document.querySelector(eventListSelector);

  [...eventList.children].forEach(event => {
    const eventDate = new Date(event.dataset['eventdate']);
    const eventEndDate = event.dataset['enddate'] ? new Date(event.dataset['enddate']) : null;

    event.classList.add('tw-hidden');
    event.classList.remove('tw-flex');

    if (eventDate.toDateString() === clickedDate.toDateString() || (eventEndDate && clickedDate >= eventDate && clickedDate <= eventEndDate)) {
      event.classList.add('tw-flex');
      event.classList.remove('tw-hidden');
    };
  });

  eventList.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest'
  });
}


/**
 * Initializes a calendar with provided events and renders it in the specified calendar element.
 *
 * @param {Array} events - Array of event objects containing event details.
 * @param {HTMLElement} calendarElement - The DOM element where the calendar will be rendered.
 * @param {HTMLElement} tourId - The tour id
 *
 * @example
 * // Sample event object format
 * const events = [
 *   {
 *     eventdate: '2023-07-01',
 *     enddate: '2023-07-03',
 *     available: true,
 *     // other event properties
*   },
 * ];
 * const calendarElement = document.getElementById('calendar');
 * initCalendar(events, calendarElement);
 */
function initCalendar(events = [], calendarElement, tourId = '') {
  if (!calendarElement) {
    throw new Error('no calendar element provided')
  }

  const language = wlec.pageData.language;

  // we reduce the events to the points we want to render in the calendar
  /** @type {import('fullcalendar').EventSourceInput} */
  const eventPoints = events.reduce((accumilator, event) => {
    let eventEndDate = new Date(event.eventdate);
    let eventDate = new Date(event.eventdate);

    const timeRegex = /^([01]?\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    const dateRegex = /\d{4}-\d{2}-\d{2}/;

    if(timeRegex.test(event.enddate) && event.eventdate) {
      const startDate = event.eventdate.match(dateRegex);
      eventEndDate = new Date(startDate + ' ' + event.enddate);
    } else if (event.enddate) {
      eventEndDate = new Date(event.enddate);
    }

    eventDate.setHours(0,0,0)

    for (
      const date = eventDate;
      date <= eventEndDate;
      date.setDate(date.getDate() + 1)
    ) {
      const existingEvent = accumilator.findIndex((mapped) => {
        return mapped.start.getDate() === date.getDate() &&
          mapped.start.getMonth() === date.getMonth() &&
          mapped.start.getFullYear() === date.getFullYear();
      });

      if (existingEvent === -1) {
        const borderColor = event.available ? '#34A334' : '#F78B8B';

        accumilator.push({
          start: new Date(date.toISOString()),
          borderColor,
          extendedProps: event
        });

        continue;
      }

      // if there is already an existing event which is unavailable we want to overide it as the new one might be available
      if (!accumilator[existingEvent].extendedProps.available) {
        accumilator[existingEvent] = {
          start: new Date(date.toISOString()),
          borderColor: event.available ? '#34A334' : '#F78B8B',
          extendedProps: event
        }

        continue;
      }

    }
    return accumilator;
  }, [])

  // find first availabe event
  let firstEvent = events.find(event => {
    const startDate = new Date(event.eventdate);
    const endDate = event.enddate ? new Date(event.enddate) : null;
    // enddate might have no date which results in the date object being an invalid date
    const date = endDate && !isNaN(endDate) ? endDate : startDate;
    return event.available && date > new Date();
  });

  // find first event
  firstEvent ||= events.find(event => {
    const startDate = new Date(event.eventdate);
    const endDate = event.enddate ? new Date(event.enddate) : null;
    // enddate might have no date which results in the date object being an invalid date
    const date = endDate && !isNaN(endDate) ? endDate : startDate;
    return date > new Date();
  });

  // fallback to today
  firstEvent ||= { eventdate: new Date()};
  
  // if date is multidate and has already started the selected date should be today.
  if (new Date(firstEvent.eventdate) < new Date()) {
    firstEvent = { eventdate: new Date()};
  }

  /** @type {import('fullcalendar').CalendarOptions} */
  const calendarOptions = {
    progressiveEventRendering: true,
    initialView: 'dayGridMonth',
    eventDisplay: 'list-item',
    height: matchMedia('(max-width: 500px)').matches ? 500 : 600,
    aspectRatio: 1,
    locale: language,
    headerToolbar: {
      left: 'prev',
      center: 'title',
      right: 'next',
    },
    displayEventTime: false,
    dateClick: (info) => onClickDate(info.dateStr, tourId),
    events: eventPoints,
    initialDate: new Date(firstEvent.eventdate),
  }

  const calendar = new Calendar(calendarElement, calendarOptions);

  calendar.render();

  if (firstEvent) {
    onClickDate(firstEvent.eventdate, tourId);
  }

  [...document.getElementsByClassName('fc-button')].forEach(button => {
    button.addEventListener('click', () => colorSelectedDay(tourId));
    button.classList.replace('fc-button-primary', 'tw-btn-primary');
    button.classList.replace('fc-button', 'tw-btn')
  })

  const fieldset = document.getElementById(`fieldset-${tourId}`);

  const handleChange = function (event) {
    document.location.hash = '#' + event.target.id;
    calendar.updateSize();
  };

  fieldset.addEventListener('change', handleChange);

  window.addEventListener('resize', calendar.updateSize);

  window.addEventListener('load', calendar.updateSize)
}

function initSort(tourId = '') {
  const orderDirection = document.getElementById(`sort${tourId}`);
  const order = document.getElementById(`order-by${tourId}`);
  const eventList = document.getElementById(`list-wrapper${tourId}`);
  const aToZ = order.querySelector('[value="title"]');

  const sortList = () => {
    const sortKey = order.value;

    [...eventList.children].sort((eventA, eventB) => {
      let comparison = 0;

      if (sortKey === 'date') {
        comparison = new Date(eventA.dataset.date) - new Date(eventB.dataset.date);
      } else {
        comparison = eventA.dataset[sortKey].localeCompare(eventB.dataset[sortKey]);
      }

      return orderDirection.checked ? -comparison : comparison;
    }).forEach(node => {
      eventList.appendChild(node);
    });
  }

  sortList()

  orderDirection.addEventListener('change', () => {
    sortList();
    aToZ.textContent = orderDirection.checked ? 'Z-A' : 'A-Z';
  });
  order.addEventListener('change', sortList);
}

window.initCalendar = initCalendar;
window.initSort = initSort;